import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {logoutUser} from 'app/auth/store/userSlice';
import {makeStyles} from '@material-ui/core/styles';
import clsx from 'clsx';
import _ from 'lodash';
import {capitalizeWords} from 'app/main/apps/scheduler/task-board/utils/utils';

const useStyles = makeStyles({
	avatarName: {
		color: 'white',
		background: '#7b7b7b'
	}
});

function UserMenu(props) {
	const dispatch = useDispatch();
	const user = useSelector(({auth}) => auth.user);
	const classes = useStyles();

	const [userMenu, setUserMenu] = useState(null);
	const [userRole, setUserRole] = useState('Guest');
	const [userName, setUserName] = useState(user?.data?.displayName);

	useEffect(() => {
		const schedulerRole = user?.data?.userData?.schedulerRole || "Guest";
		setUserRole(schedulerRole);
		const name = capitalizeWords(user?.data?.displayName);
		setUserName(name);
	}, [user?.data?.userData]);

	const userMenuClick = event => {
		setUserMenu(event.currentTarget);
	};

	const userMenuClose = () => {
		setUserMenu(null);
	};

	return (
		<>
			<Button className="min-h-40 min-w-85 px-0 md:px-16 py-0 md:py-6" onClick={userMenuClick}>
				<div className="items-start hidden md:flex flex-col mx-4">
					<Typography component="span" className="text-11 flex">
						{userName}
					</Typography>
					<Typography className="text-11 font-bold capitalize" color="textSecondary">
						{userRole}
					</Typography>
				</div>

				{user.data.photoURL ? (
					<Avatar className="md:mx-4" alt="user photo" src={user?.data?.photoURL} />
				) : (
					<Avatar className={clsx('md:mx-4', classes.avatarName)}>{user?.data?.displayName[0]}</Avatar>
				)}
			</Button>

			<Popover
				open={Boolean(userMenu)}
				anchorEl={userMenu}
				onClose={userMenuClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center'
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center'
				}}
				classes={{
					paper: 'py-8'
				}}
			>
				{!user.role || user.role.length === 0 ? (
					<>
						<MenuItem component={Link} to="/login" role="button">
							<ListItemIcon className="min-w-40">
								<Icon>lock</Icon>
							</ListItemIcon>
							<ListItemText primary="Login" />
						</MenuItem>
						<MenuItem component={Link} to="/register" role="button">
							<ListItemIcon className="min-w-40">
								<Icon>person_add</Icon>
							</ListItemIcon>
							<ListItemText primary="Register" />
						</MenuItem>
					</>
				) : (
					<>
						<MenuItem component={Link} to="/main/profile" onClick={userMenuClose} role="button">
							<ListItemIcon className="min-w-40">
								<Icon>account_circle</Icon>
							</ListItemIcon>
							<ListItemText primary="My Profile" />
						</MenuItem>
						{/* <MenuItem component={Link} to="/apps/mail" onClick={userMenuClose} role="button">*/}
						{/*	<ListItemIcon className="min-w-40">*/}
						{/*		<Icon>mail</Icon>*/}
						{/*	</ListItemIcon>*/}
						{/*	<ListItemText primary="Inbox" />*/}
						{/* </MenuItem>*/}
						<MenuItem
							onClick={() => {
								dispatch(logoutUser());
								userMenuClose();
							}}
						>
							<ListItemIcon className="min-w-40">
								<Icon>exit_to_app</Icon>
							</ListItemIcon>
							<ListItemText primary="Logout" />
						</MenuItem>
					</>
				)}
			</Popover>
		</>
	);
}

export default UserMenu;
