import {createSlice} from '@reduxjs/toolkit';
import _ from 'lodash';
import {getTechnicians} from './api/schedulerCommonApi';
import {getStateCounties} from './api/stateCountyService';
import { getTaskMasterData } from '../task-board/store/api/taskApi';
import { getBusEntityNoteCount, getBusinessEntityEx } from '../task-board/store/api/taskCustomerApi';
import { processSampleLocation } from '../task-board/store/api/processTaskLocation';
import { createSLwithLocAndEquip, getEquipmentEx, getLocationEx, getSampleLocationEx, updateLocationCoordinates, updateSLwithLocAndEquip } from '../task-board/store/api/taskLocationApi';

const schedulerCommonSlice = createSlice({
	name: 'schedulerCommon',
	initialState: {
		gridSearchTerm: '',
		appBarModuleTitle: '',

		technicians: [],
    	taskTypes: [],

		isBeSlLocEquipLoading: false,
		businessEntities: [],
		businessEntityIdMap: {},
		busEntityNoteCountMap: {},
		isBusEntityLoading: false,

		sampleLocations: [],
		sampleLocationIdMap: {},
		isSampleLocationLoading: false,
		
		stateCounties: [],
		locations: [],
		locationIdMap: {},
		isLocationLoading: false,

		equipment: [],
		equipmentIdMap: {},
		isEquipmentLoading: false,

        gridFilterOptions: {
			sampleLocName: [],
            locName: [],
            clientName: [],
            status: ['Assigned', 'Completed', 'Incomplete', 'New'],
            recurringType: ['Monthly', 'Weekly', 'Yearly'],
            frequencyTag: ['Annual', 'Bi-Monthly', 'Bi-Weekly', 'Monthly', 'Quarterly', 'Semi-Annual', 'Weekly'],
            assignedUsers: [],
            isRecurringTask: ['Yes','No'],
            isActive: ['Yes','No'],
            taskType: [],
        }
	},
	reducers: {
		setGridSearchTerm: (state, action) => {
			state.gridSearchTerm = action.payload;
		},
		setAppBarModuleTitle: (state, action) => {
			state.appBarModuleTitle = action.payload;
		},
		updateBeNoteCount: (state, action) => {
            const {beId, count = 0} = action.payload
            // count value is 1 for add and -1 for delete
            const beCountMap = state.busEntityNoteCountMap
            if(beId) {
                const existingCount = beCountMap[beId] || 0
                beCountMap[beId] = (existingCount + count) >= 0 ? existingCount + count : 0
            }
            state.busEntityNoteCountMap = beCountMap
        },
	},
	extraReducers: builder => {
		builder
			.addCase(getTechnicians.fulfilled, (state, action) => {
				const techniciansList = action.payload?.map(val=>val.technicianFullName)?.sort();
				state.technicians = action.payload;
				state.gridFilterOptions = {
					...state.gridFilterOptions,
					assignedUsers: ['Unassigned',...techniciansList, 'Third Party']
				}
			})
			.addCase(getStateCounties.fulfilled, (state, action) => {
				state.stateCounties = action.payload;
			})
			.addCase(getTaskMasterData.fulfilled, (state, action) => {
				state.taskTypes = action.payload
				state.gridFilterOptions = {
					...state.gridFilterOptions,
					taskType: _.uniq(action.payload?.filter(val=>val.taskType)?.map(val=>val.taskType)?.sort())
				}
			})
			.addCase(getBusinessEntityEx.pending, (state) => {
				state.isBusEntityLoading = true;
				state.isBeSlLocEquipLoading = true;
			})
			.addCase(getBusinessEntityEx.rejected, (state) => {
				state.isBusEntityLoading = false;
				state.isBeSlLocEquipLoading = state.isSampleLocationLoading || state.isLocationLoading || state.isEquipmentLoading;
			})
			.addCase(getBusinessEntityEx.fulfilled, (state, action) => {
				const data = action.payload || []
				state.businessEntities = [...data].sort((a, b) =>
					a.shortName?.localeCompare(b.shortName)
				);
				state.businessEntityIdMap = createIdMap(action.payload);
				state.isBusEntityLoading = false;
				state.isBeSlLocEquipLoading = state.isSampleLocationLoading || state.isLocationLoading || state.isEquipmentLoading;
				state.gridFilterOptions = {
					...state.gridFilterOptions,
					clientName: _.uniq(action.payload?.filter(val=>val.shortName)?.map(val=>val.shortName)?.sort())
				}
			})

			.addCase(getSampleLocationEx.pending, (state) => {
				state.isSampleLocationLoading = true;
				state.isBeSlLocEquipLoading = true;
			})
			.addCase(getSampleLocationEx.rejected, (state, action) => {
				state.isSampleLocationLoading= false;
				state.isBeSlLocEquipLoading = state.isBusEntityLoading || state.isLocationLoading || state.isEquipmentLoading;
			})
			.addCase(getSampleLocationEx.fulfilled, (state, action) => {
				state.sampleLocations =  _.filter(action.payload, sl => !_.isEmpty(sl.name));
				state.sampleLocationIdMap = createIdMap(action.payload);
				state.isSampleLocationLoading = false;
				state.isBeSlLocEquipLoading = state.isBusEntityLoading || state.isLocationLoading || state.isEquipmentLoading;
				state.gridFilterOptions = {
					...state.gridFilterOptions,
					sampleLocName: _.uniq(action.payload?.filter(val=>val.name)?.map(val=>val.name)?.sort())
				}
			})

			.addCase(getLocationEx.pending, (state) => {
				state.isLocationLoading = true;
				state.isBeSlLocEquipLoading = true;
			})
			.addCase(getLocationEx.rejected, (state) => {
				state.isLocationLoading= false;
				state.isBeSlLocEquipLoading = state.isBusEntityLoading || state.isSampleLocationLoading || state.isEquipmentLoading;
			})
			.addCase(getLocationEx.fulfilled, (state, action) => {
				state.locations = _.filter(action.payload, loc => !_.isEmpty(loc.locName));
				state.locationIdMap = createIdMap(action.payload);
				state.isLocationLoading = false;
				state.isBeSlLocEquipLoading = state.isBusEntityLoading || state.isSampleLocationLoading || state.isEquipmentLoading;
				state.gridFilterOptions = {
					...state.gridFilterOptions,
					locName: _.uniq(action.payload?.filter(val=>val.locName)?.map(val=>val.locName)?.sort())
				}
			})

			.addCase(getEquipmentEx.pending, (state) => {
				state.isEquipmentLoading = true;
				state.isBeSlLocEquipLoading = true;
			})
			.addCase(getEquipmentEx.rejected, (state) => {
				state.isEquipmentLoading= false;
				state.isBeSlLocEquipLoading = state.isBusEntityLoading || state.isSampleLocationLoading || state.isLocationLoading;
			})
			.addCase(getEquipmentEx.fulfilled, (state, action) => {
				const equipmentArr = _.filter(action.payload, equip => !_.isEmpty(equip.name));
				state.equipment = equipmentArr
				state.equipmentIdMap = createIdMap(action.payload);
				state.isEquipmentLoading = false;
				state.isBeSlLocEquipLoading = state.isBusEntityLoading || state.isSampleLocationLoading || state.isLocationLoading;
			})
			.addCase(createSLwithLocAndEquip.fulfilled, (state, action) => {
				const {sampleLocation, location, equipment} = action?.payload?.data || {}
				let { locationIdMap } = state
				if(location?.id){
					locationIdMap = { ...state.locationIdMap, [location.id]: location}
					const newLocations = [...state.locations, location]
					state.locations = newLocations
					state.locationIdMap = locationIdMap
					state.gridFilterOptions = {
						...state.gridFilterOptions,
						locName: _.uniq(newLocations?.filter(val=>val.locName)?.map(val=>val.locName)?.sort())
					}
				}
				const sampleLoc = processSampleLocation(sampleLocation, locationIdMap);
				if(sampleLoc?.id){
					const newSampleLocations = [...state.sampleLocations, sampleLoc]
					state.sampleLocations = newSampleLocations
					state.sampleLocationIdMap = {
						...state.sampleLocationIdMap,
						[sampleLoc.id]: sampleLoc 
					} 
					state.gridFilterOptions = {
						...state.gridFilterOptions,
						sampleLocName: _.uniq(newSampleLocations?.filter(val=>val.name)?.map(val=>val.name)?.sort())
					}
				}
				if(equipment?.id){
					state.equipment = [...state.equipment, equipment]
					state.equipmentIdMap = {
						...state.equipmentIdMap,
						[equipment.id]: equipment 
					} 
				}
			})
			.addCase(updateSLwithLocAndEquip.fulfilled, (state, action) => {
				const {sampleLocation, location, equipment} = action?.payload?.data || {}
				let { locationIdMap } = state.locationIdMap
				if(location?.id){
					locationIdMap = { ...state.locationIdMap, [location.id]: location}
					const newLocations = state.locations.map(item => item.id === location.id ? location : item)
					state.locations = newLocations
					state.locationIdMap = locationIdMap
					state.gridFilterOptions = {
						...state.gridFilterOptions,
						locName: _.uniq(newLocations?.filter(val=>val.locName)?.map(val=>val.locName)?.sort())
					}
				}
				if(equipment?.id){
					state.equipment = state.equipment.map(item => item.id === equipment.id ? equipment : item)
					state.equipmentIdMap = {
						...state.equipmentIdMap,
						[equipment.id]: equipment 
					} 
				}
				const sampleLoc = processSampleLocation(sampleLocation, locationIdMap);
				if(sampleLoc?.id){
					const newSampleLocations = state.sampleLocations.map(item => item.id === sampleLoc.id ? sampleLoc : item)
					state.sampleLocations = newSampleLocations
					state.sampleLocationIdMap = {
						...state.sampleLocationIdMap,
						[sampleLoc.id]: sampleLoc 
					} 
					state.gridFilterOptions = {
						...state.gridFilterOptions,
						sampleLocName: _.uniq(newSampleLocations?.filter(val=>val.name)?.map(val=>val.name)?.sort())
					}
				}
			})
			.addCase(getBusEntityNoteCount.fulfilled, (state, action) => {
				state.busEntityNoteCountMap = action.payload
			})
			.addCase(updateLocationCoordinates.fulfilled, (state, action) => {
				const {id, coordLonLat} = action.meta.arg
				const location = state.locationIdMap[id]
				location.lngLat = coordLonLat
				state.locationIdMap = { ...state.locationIdMap, [location.id]: location}
				state.locations = state.locations.map(item => item.id === location.id ? location : item)
			})
			;
	}
});

export const {
	setGridSearchTerm,
	setAppBarModuleTitle,
	updateBeNoteCount
} = schedulerCommonSlice.actions;

export default schedulerCommonSlice.reducer;

function createIdMap(data = []){
    const map = {}
    _.forEach(data, item => {
        map[item.id] = item
    })
    return map
}