import React from 'react';
import authRoles from '../../../auth/authRoles';

const SchedulerConfig = {
	settings: {
		layout: {
			style: 'layout2',
			scroll: 'content',
			config: {}
		}
	},
	auth: [authRoles.admin, authRoles.staff, authRoles.user, authRoles.analyst, authRoles.manager, authRoles.taskLead, authRoles.technician],
	routes: [
		/*
		{
			path: '/main/request-analysis',
			component: React.lazy(() => import('./request-analysis/RequestAnalysisApp')),
		},
		*/
		{
			path: '/main/task-board',
			component: React.lazy(() => import('./task-board/index'))
		},
		{
			path: '/main/profile',
			component: React.lazy(() => import('./profile/ProfileHome'))
		}
	]
};

export default SchedulerConfig;
